import React from 'react';
import './furnituretype.css'
import { useNavigate } from 'react-router-dom';
import office from '../../../../../Assets/office.webp'
import household from '../../../../../Assets/household.webp'
import outdoor from '../../../../../Assets/outdoor.webp'
import decoration from '../../../../../Assets/decoration.webp'
import others from '../../../../../Assets/others.webp'

const FurnitureType = () => {
  const navigate = useNavigate();


  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    width: '100px', // Adjust the width as needed
    // background: 'url("https://www.osimo.com.tr/assets/images/media-bg.jpg") center/cover no-repeat',
  };

  const imageStyle = {
    width: '80px',
    height: '80px',
    marginBottom: '16px',
  };

  return (
    <div className=' flex justify-evenly mb-6'>
    <div className='maindiv flex  justify-evenly  flex-wrap bg-white w-5/6 pt-5' >
              <div style={containerStyle} onClick={() => {navigate(`/subcategories/Office`)}}>
        <img src={office} alt="Fairs" style={imageStyle} />
        <h1>Office </h1>

      </div>
      <div style={containerStyle} onClick={() => {navigate(`/subcategories/Household`)}}>
        <img src={household} alt="Fairs" style={imageStyle} />
        <h1>Household</h1>
      </div>

      <div style={containerStyle} onClick={() => {navigate(`/subcategories/Outdoor`)}}>
        <img src={outdoor} alt="Fairs" style={imageStyle} />
        <h1>Outdoor</h1>

      </div>
      <div style={containerStyle} onClick={() => {navigate(`/subcategories/Decorations`)}}>
        <img src={decoration} alt="Fairs" style={imageStyle} />
        <h1>Decorations</h1>

      </div>
      <div style={containerStyle} onClick={() => {navigate(`/subcategories/Furniture Others`)}}>
        <img src={others} alt="Fairs" style={imageStyle} />
        <h1>Others</h1>
      </div>

      

      </div>      
    </div>
  );
}

export default FurnitureType;
