import React from "react"
import inner from '../../../../Assets/inner.webp'

const UserListHeader = () => {
    const divStyle = {
        backgroundImage: `url(${inner})`,
        backgroundSize: "cover",
        backgroundPosition: "center",

      };
    
      const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        width: '1000px', 
        height: '250px',
      };
    
  return (
    <div className='flex  justify-evenly flex-wrap my-4' style={divStyle}>
      <div style={containerStyle} className="text-white">
        <h1 className="text-6xl font-extrabold opacity-100">Your Listings</h1>

      </div>

    </div>
  )
}

export default UserListHeader;