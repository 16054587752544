import React from 'react';
import { useState } from 'react';
import './propertytype.css'
import { useNavigate } from 'react-router-dom';
import sale from '../../../../../Assets/sale.webp'
import rent from '../../../../../Assets/rent.webp'
import homes from '../../../../../Assets/homes.webp'
import plots from '../../../../../Assets/plots.webp'
import comm from '../../../../../Assets/comm.webp'
import project from '../../../../../Assets/project.webp'
import room from '../../../../../Assets/room.webp'
import agents from '../../../../../Assets/agents.webp'
import others from '../../../../../Assets/others.webp'

const PropertyType = () => {
  const navigate = useNavigate();


  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    width: '100px', // Adjust the width as needed
    // background: 'url("https://www.osimo.com.tr/assets/images/media-bg.jpg") center/cover no-repeat',
  };

  const imageStyle = {
    width: '80px',
    height: '80px',
    marginBottom: '16px',
  };


  return (
    <div className=' flex justify-evenly mb-6'>
    <div className='maindiv flex  justify-evenly  flex-wrap bg-white w-5/6 pt-5' >
              <div style={containerStyle}  onClick={() => {navigate(`/subcategories/Sale`)}}>
        <img src={sale} alt="Fairs" style={imageStyle} />
        <h1>Sale</h1>

      </div>
      <div style={containerStyle} onClick={() => {navigate(`/subcategories/Rent`)}}>
        <img src={rent} alt="Fairs" style={imageStyle} />
        <h1>Rent</h1>
      </div>

      <div style={containerStyle} onClick={() => {navigate(`/subcategories/Homes`)}}>
        <img src={homes} alt="Fairs" style={imageStyle} />
        <h1>Homes</h1>

      </div>
      <div style={containerStyle} onClick={() => {navigate(`/subcategories/Plots`)}}>
        <img src={plots} alt="Fairs" style={imageStyle} />
        <h1>Plots</h1>

      </div>
      <div style={containerStyle} onClick={() => {navigate(`/subcategories/Commercial`)}}>
        <img src={comm} alt="Fairs" style={imageStyle} />
        <h1>Commercial</h1>

      </div>
      <div style={containerStyle} onClick={() => {navigate(`/subcategories/Projects`)}}>
        <img src={project} alt="Fairs" style={imageStyle} />
        <h1>Projects</h1>

      </div>
      <div style={containerStyle} onClick={() => {navigate(`/subcategories/Rooms`)}}>
        <img src={room} alt="Fairs" style={imageStyle} />
        <h1>Rooms</h1>

      </div>
      
      <div style={containerStyle} onClick={() => {navigate(`/subcategories/Agents`)}}>
        <img src={agents} alt="Fairs" style={imageStyle} />
        <h1>Agents </h1>

      </div>
      <div style={containerStyle} onClick={() => {navigate(`/subcategories/Property Others`)}}>
        <img src={others} alt="Fairs" style={imageStyle} />
        <h1>Others</h1>
      </div>
      </div>      
    </div>
  );
}

export default PropertyType;
