import React from "react"
import beautyhead from '../../../../../Assets/Beautyhead.webp'

const BeautyHeader = () => {
    const divStyle = {
        backgroundImage: `url(${beautyhead})`,
        backgroundSize: "cover",
        backgroundPosition: "center",

      };
    
      const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        width: '1000px', // Adjust the width as needed
        height: '250px',
        paddingBottom: '100px'
      };
    
  return (
    <div className='flex  justify-evenly flex-wrap my-4' style={divStyle}>
      <div style={containerStyle} className="text-white">
        <h1 className="text-6xl font-extrabold opacity-100">Beauty Parlour</h1>

      </div>




      
    </div>
  )
}

export default BeautyHeader;