import React, { useEffect, useState } from 'react';
import { Navbar } from 'flowbite-react';
import logo from '../../../Assets/logomains.webp'
import axios from 'axios';
const AdminNAvbar = () => {
  let [customeruser, setcustomeruser] = useState(false);
  let [customerresponse, setcustomerresponse] = useState("");
  let [customerbring, setcustomerbring] = useState(false);


  useEffect(() => {
    const getProfile = async () => {
      try {
        let response = await axios.get(
          `https://list-back-gn1y.vercel.app/api/v1/listerprofile`,
          {
            withCredentials: true,
            headers: {
              "Cache-Control": "no-cache",
              Pragma: "no-cache",
              Expires: "0",
            },
          }
        );

        setcustomerresponse(response.data);
        setcustomeruser(true);
        setcustomerbring(customerresponse.firstname);


      } catch (error) {
        console.log("axios error: ", error);
      }
    };
    getProfile();
  }, []);
  return (
    <div>
      
      <Navbar fluid rounded>
      <Navbar.Brand href="https://flowbite-react.com">
        <img src={logo} className="mr-3 h-6 sm:h-9" alt="Flowbite React Logo" />
      </Navbar.Brand>
      <div className="flex md:order-2">

        
        <Navbar.Toggle />
      </div>
      <Navbar.Collapse>
        <Navbar.Link href="/dashboardadmin" active className='text-lg'> 
          Dashboard
        </Navbar.Link>
        <Navbar.Link href="/listdisplayadmin" className='text-lg'> Listings</Navbar.Link>
        <Navbar.Link href="/approvelistadmin" className='text-lg'> Approval</Navbar.Link>
        <Navbar.Link href="/alluseradmin" className='text-lg'> Users</Navbar.Link>
        <Navbar.Link href="/bestsellermanage" className='text-lg'> Best Listings</Navbar.Link>
        <Navbar.Link href="/bestsellermanage" className='text-lg'> Hot Listings</Navbar.Link>

      </Navbar.Collapse>
    </Navbar>


    
    </div>
  )
}

export default AdminNAvbar