import React, { useState } from 'react';
import './registerform.css'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { TextField } from '@mui/material';
const RegisterForm = () => {
  const navigate = useNavigate();
  const [email, setemail] = useState()
  const [firstname, setFirstName] = useState()
  const [lastname, setLastName] = useState()
  const [phone, setPhone] = useState()
  const [postal, setPostal] = useState()
  const [address, setAddress] = useState()
  const [city, setCity] = useState()
  const [state, setState] = useState()
  const [password, setPassword] = useState()
  const [reTypepassword, setReTypePassword] = useState()
  const [reTypePasswordError, setReTypePasswordError] = useState(false);


  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',

    width: '850px', // Adjust the width as needed
    // background: 'url("https://www.osimo.com.tr/assets/images/media-bg.jpg") center/cover no-repeat',
  };


  const signupForm = async () => {
    console.log('Signup Form');
    if (password !== reTypepassword) {
      setReTypePasswordError('Passwords do not match');
      return;
    }
 
    if (firstname && email && phone && postal && address &&city && password && reTypepassword) {
      if (/^((\+92)?(0092)?(92)?(0)?)(3)([0-9]{9})$/gm.test(phone)) {
        if (/^([0-9]{5})$/.test(postal)) {
      if (/^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/.test(email))
      { 
      try {
        const response = await axios.post('https://list-back-gn1y.vercel.app/listerregister', {
          firstname,
          lastname,
          email,
          phone,
          postal,
          address,
          city,
          state,
          password,
        });

        if (response.status === 201) {
          console.log('Signup successful');
          alert("you have successfully registered");
          navigate('/signin')
        } else {
          console.log('Signup failed');

        }
      } catch (error) {
        console.error(error);
        alert("User with this Email ID already exist!")
      }
   

    }
    else {alert("Invalid Email")}
  }     else {alert("Invalid Postal code")}

  }     else {alert("Invalid Mobile Number")}


  }  else {
    alert("Kindly fill all the fileds")
  }
  };

  return (
    <>
    
    <>
          <div className=' flex justify-evenly mb-6'>
    <div className='maindiv flex  justify-evenly flex-wrap bg-white w-5/6 pt-5' >

              <div style={containerStyle}>
            
        <div className='mx-10 my-6 '>
        <div className="grid md:grid-cols-2 md:gap-6">
    <div className="relative z-0 w-full mb-6 group">
    <TextField fullWidth  name="firstname" id="firstname" onChange={(event) => { setFirstName(event.target.value); }} placeholder='Enter First Name' variant="outlined" className='block py-2.5 px-0 w-full focus:text-white text-sm text-white  border-0 border-b-2 border-white appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer'/>

    </div>
    <div className="relative z-0 w-full mb-6 group">
    <TextField fullWidth  type="text" name="lastname"  id="lastname" onChange={(event) => { setLastName(event.target.value); }} placeholder='Enter Last Name' variant="outlined" className='block py-2.5 px-0 w-full focus:text-white text-sm text-white  border-0 border-b-2 border-white appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer'/>

    </div>
    
  </div>
  <div className="grid md:grid-cols-2 md:gap-6">
  <div className="relative z-0 w-full mb-6 group">
   <TextField fullWidth  type="number" name="phone"  id="phone" onChange={(event) => { setPhone(event.target.value); }} placeholder='Enter Mobile Number' variant="outlined" className='block py-2.5 px-0 w-full focus:text-white text-sm text-white  border-0 border-b-2 border-white appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer'/>

  </div>
  <div className="relative z-0 w-full mb-6 group">
  <TextField fullWidth type='number' name="postal" id="postal" onChange={(event) => { setPostal(event.target.value); }} placeholder='Enter Postal' variant="outlined" className='block py-2.5 px-0 w-full focus:text-white text-sm text-white  border-0 border-b-2 border-white appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer'/>

  </div>
  </div>
  <div className="grid md:grid-cols-3 md:gap-6">
  <div className="relative z-0 w-full mb-6 group">
   <TextField fullWidth  type="email" name="email" id="email" onChange={(event) => { setemail(event.target.value); }} placeholder='Enter Email' variant="outlined" className='block py-2.5 px-0 w-full focus:text-white text-sm text-white  border-0 border-b-2 border-white appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer'/>

  </div>
  <div className="relative z-0 w-full mb-6 group">
 <TextField fullWidth  type="password" name="password" id="password" onChange={(event) => { setPassword(event.target.value); }} placeholder='Enter Password' variant="outlined" className='block py-2.5 px-0 w-full focus:text-white text-sm text-white  border-0 border-b-2 border-white appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer'/>

  </div>
  <div className="relative z-0 w-full mb-6 group">
  <TextField fullWidth type="password" name="retype" id="retype" onChange={(event) => { setReTypePassword(event.target.value); setReTypePasswordError("") }} placeholder='Retype Password' variant="outlined" className='block py-2.5 px-0 w-full focus:text-white text-sm text-white  border-0 border-b-2 border-white appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer'/>

      {reTypePasswordError && <p className="error-message text-red-500">{reTypePasswordError}</p>} 

  </div>
  </div>

  <div className="relative z-0 w-full mb-6 group">
      <input name="address" id="address" onChange={(event) => { setAddress(event.target.value); }} className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
      <label for="address" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Address</label>
      <TextField fullWidth name="address" id="address" onChange={(event) => { setAddress(event.target.value); }} placeholder='Enter Address' variant="outlined" className='block py-2.5 px-0 w-full focus:text-white text-sm text-white  border-0 border-b-2 border-white appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer'/>

  </div>


  


  <div className="grid md:grid-cols-2 md:gap-6">

    <div className="relative z-0 w-full mb-6 group">
  <TextField fullWidth  name="city" id="city" onChange={(event) => { setCity(event.target.value); }} placeholder='Enter City' variant="outlined" className='block py-2.5 px-0 w-full focus:text-white text-sm text-white  border-0 border-b-2 border-white appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer'/>

    </div>
    <div className="relative z-0 w-full mb-6 group">
   <TextField fullWidth type="text" name="state" id="state" onChange={(event) => { setState(event.target.value);}} placeholder='Enter State' variant="outlined" className='block py-2.5 px-0 w-full focus:text-white text-sm text-white  border-0 border-b-2 border-white appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer'/>

    </div>
  </div>
  <button type="submit" onClick={signupForm} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Register</button>
</div>

        
      </div>
      

      </div>   
    </div>
      </>

    </>
  );
}

export default RegisterForm;
