import React from 'react';
import './eventtype.css'
import { useNavigate } from 'react-router-dom';
import wed from '../../../../../Assets/wed.webp'
import birth from '../../../../../Assets/birth.webp'
import catering from '../../../../../Assets/catering.webp'
import anniversary from '../../../../../Assets/anniversary.webp'
import festival from '../../../../../Assets/festival.webp'
import exibition from '../../../../../Assets/exibition.webp'
import seminar from '../../../../../Assets/seminar.webp'
import music from '../../../../../Assets/music.webp'
import others from '../../../../../Assets/others.webp'

const EventType = () => {
  const navigate = useNavigate();

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    width: '100px', // Adjust the width as needed
    // background: 'url("https://www.osimo.com.tr/assets/images/media-bg.jpg") center/cover no-repeat',
  };

  const imageStyle = {
    width: '80px',
    height: '80px',
    marginBottom: '16px',
  };

  return (
    <div className=' flex justify-evenly mb-6'>
    <div className='maindiv flex  justify-evenly  flex-wrap bg-white w-5/6 pt-5' >
              <div style={containerStyle} onClick={() => {navigate(`/subcategories/Wedding`)}}>
        <img src={wed} alt="Fairs" style={imageStyle} />
        <h1>Wedding</h1>

      </div>
      <div style={containerStyle} onClick={() => {navigate(`/subcategories/Birthday`)}}>
        <img src={birth} alt="Fairs" style={imageStyle} />
        <h1>Birthday</h1>
      </div>

      <div style={containerStyle} onClick={() => {navigate(`/subcategories/Catering`)}}>
        <img src={catering} alt="Fairs" style={imageStyle} />
        <h1>Catering</h1>

      </div>
      <div style={containerStyle} onClick={() => {navigate(`/subcategories/Anniversary`)}}>
        <img src={anniversary} alt="Fairs" style={imageStyle} />
        <h1>Anniversary</h1>

      </div>
      <div style={containerStyle} onClick={() => {navigate(`/subcategories/Festival`)}}>
        <img src={festival} alt="Fairs" style={imageStyle} />
        <h1>Festival</h1>

      </div>

      <div style={containerStyle} onClick={() => {navigate(`/subcategories/Exibition`)}}>
        <img src={exibition} alt="Fairs" style={imageStyle} />
        <h1>Exibition</h1>
      </div>
      <div style={containerStyle} onClick={() => {navigate(`/subcategories/Seminar`)}}>
        <img src={seminar} alt="Fairs" style={imageStyle} />
        <h1>Seminar</h1>

      </div>
      <div style={containerStyle} onClick={() => {navigate(`/subcategories/Entertainment`)}}>
        <img src={music} alt="Fairs" style={imageStyle} />
        <h1>Entertainment</h1>

      </div>
      <div style={containerStyle} onClick={() => {navigate(`/subcategories/Event Others`)}}>
        <img src={others} alt="Fairs" style={imageStyle} />
        <h1>Others</h1>
      </div>
      </div>      
    </div>
  );
}

export default EventType;
