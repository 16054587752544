import React from 'react';
import property2 from '../../../../Assets/property2.webp'
import fashion2 from '../../../../Assets/fashion2.webp'
import jobs2 from '../../../../Assets/jobs2.webp'
import service2 from '../../../../Assets/service2.webp'
import furniture2 from '../../../../Assets/furniture2.webp'
import health from '../../../../Assets/health.webp'
import event from '../../../../Assets/event.webp'
import beauty from '../../../../Assets/beauty.webp'
import others from '../../../../Assets/others.webp'

import { Link } from 'react-router-dom';
const HomeCategory = () => {


  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    width: '100px', // Adjust the width as needed
    // background: 'url("https://www.osimo.com.tr/assets/images/media-bg.jpg") center/cover no-repeat',
  };

  const imageStyle = {
    width: '80px',
    height: '80px',
    marginBottom: '16px',
  };


  return (
    <div className='flex  justify-evenly flex-wrap my-4' >
      <Link to="/fashion">
              <div style={containerStyle}>
        <img src={fashion2} alt="Fairs" style={imageStyle} />
        <h1>Fashion & Apparel</h1>
      </div>
      </Link>
      <Link to="/property">
      <div style={containerStyle} >
        <img src={property2} alt="Fairs" style={imageStyle} />
        <h1>Property</h1>
      </div>
      </Link>
      <Link to="/jobs">
      <div style={containerStyle}>
        <img src={jobs2} alt="Fairs" style={imageStyle} />
        <h1>Jobs</h1>

      </div>
      </Link>
      <Link to="/Services">

      <div style={containerStyle}>
        <img src={service2} alt="Fairs" style={imageStyle} />
        <h1>Services</h1>

      </div>
      </Link>
      <Link to="/furnitures">
      <div style={containerStyle}>
        <img src={furniture2} alt="Fairs" style={imageStyle} />
        <h1>Furniture</h1>

      </div>
      </Link>
      <Link to="/health">
      <div style={containerStyle}>
        <img src={health} alt="Fairs" style={imageStyle} />
        <h1>Health</h1>

      </div>
      </Link>
      <Link to="/event">
      <div style={containerStyle}>
        <img src={event} alt="Fairs" style={imageStyle} />
        <h1>Events & Planners</h1>

      </div>
      </Link>
      <Link to="/beauty">

      <div style={containerStyle}>
        <img src={beauty} alt="Fairs" style={imageStyle} />
        <h1>Beauty </h1>

      </div>
      
      </Link>
      <Link to="/others">

      <div style={containerStyle}>
        <img src={others} alt="Fairs" style={imageStyle} />
        <h1>Others </h1>

      </div>
      
      </Link>
    </div>
  );
}

export default HomeCategory;
