import React from 'react'
import 
 { BsJustify}
 from 'react-icons/bs'

function Header({OpenSidebar}) {
  return (
    <header className='header flex justify-evenly flex-wrap'>
        <div className='menu-icon flex justify-evenly flex-wrap'>
            <BsJustify className='icon' onClick={OpenSidebar}/>
        </div>

    </header>
  )
}

export default Header